import { initializeApp } from 'firebase/app';

import { getFirestore, doc, setDoc } from 'firebase/firestore/lite'

const firebaseConfig = {
	apiKey: "AIzaSyC9KEEKwVXL0vdloBYbz0NmXnAI-VL0XV8",
	authDomain: "test-e65cc.firebaseapp.com",
	projectId: "test-e65cc",
	storageBucket: "test-e65cc.appspot.com",
	messagingSenderId: "1038806035624",
	appId: "1:1038806035624:web:859a114c8cda71ef97a69d"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const main = document.querySelector(".main");
const formBody = document.querySelector(".form-body");
const inputFirstname = document.getElementById("firstName");
const inputLastname = document.getElementById("lastName");
const inputEmail = document.getElementById("email");
const form = document.getElementById("form");
const userData = {
	firstName: null,
	lastName: null,
	email: false,
}

inputFirstname.addEventListener("input", (e) => {
	userData.firstName = e.currentTarget.value
})

inputLastname.addEventListener("input", (e) => {
	userData.lastName = e.currentTarget.value
})

inputEmail.addEventListener("input", (e) => {
	userData.email = e.currentTarget.value
})

form.addEventListener("submit", async (e) => {
	e.preventDefault();

	formBody.classList.add('disabled');

	try {
		await setDoc(doc(db, "subscriptions", userData.email), {
			firstName: userData.firstName,
			lastName: userData.lastName,
		});
		console.log(`success`);

		e.target.reset()
		main.classList.add('done')
	} catch (error) {
		main.classList.add('error')
		console.log(error);		
	} finally {
		formBody.classList.remove('disabled');
	}
 })